<template>
  <div id="cash_flow">
    <div style="margin-top: 15px">
      <div
        style="
          padding: 0 20px 20px 20px;
          font-size: 14px;
          color: #333333;
          font-weight: 550;
        "
        v-if="tableList.status == 0"
      >
        {{ tableList.detail }}
      </div>

      <div id="AnalysisOfSourceAndUsageDirection" v-else>
        <div class="content_a test1" v-if="getUserRole('cf_overview')">
          <div class="titles">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            现金流概览
          </div>
          <div class="content1">
            <div class="content2">
              <WaterfallBar ref="ABar1"></WaterfallBar>
            </div>
            <div class="content2">
              <div class="row align-items-center justify-content-end">
                <div class="pr-3">
                  <el-button-group>
                    <el-button
                      @click="toggleTypes(false)"
                      size="mini"
                      style="font-size: 14px; width: 100px"
                      :class="!shows && 'btnActive'"
                      >行业对比</el-button
                    >
                    <el-button
                      @click="toggleTypes(true)"
                      size="mini"
                      style="font-size: 14px; width: 100px"
                      :class="shows && 'btnActive'"
                      >历史趋势</el-button
                    >
                  </el-button-group>
                </div>
              </div>
              <c-table
                class="mt-3"
                v-show="!shows"
                :tableData="tableData"
                style="width: 98%"
              ></c-table>
              <div
                class="mt-3"
                v-show="shows"
                style="width: 100%; height: 350px"
              >
                <div ref="linChartCunt" class="linChartCunt"></div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 15px; background: rgb(243 243 243)"></div>
        <div class="table_structure test1" v-if="getUserRole('cf_structure')">
          <div class="titles">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            现金流结构分析
          </div>

          <div class="mt-3">
            <el-button-group>
              <el-button
                size="medium"
                @click="tabClick(2)"
                :type="tableType == 2 ? 'primary' : ''"
                >现金流量净额</el-button
              >
              <el-button
                size="medium"
                @click="tabClick(0)"
                :type="tableType == 0 ? 'primary' : ''"
                >现金流入</el-button
              >
              <el-button
                size="medium"
                @click="tabClick(1)"
                :type="tableType == 1 ? 'primary' : ''"
                >现金流出</el-button
              >
            </el-button-group>
          </div>
          <div class="mt-4">
            <div v-for="(item, name, index) in tableList.result" :key="index">
              <Stable
                v-show="index == tableType"
                :tableData="item.content"
                :year="tableList.year"
              ></Stable>
              <div
                style="
                  margin-top: 20px;
                  width: 100%;
                  padding: 15px;
                  background: #f1f5ff;
                "
                v-show="index == tableType"
              >
                <p
                  style="
                    color: rgb(153, 153, 153);
                    font-size: 18px;
                    margin-bottom: 10px;
                  "
                >
                  分析小结：
                </p>
                <div v-html="item.desc"></div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 15px; background: rgb(243 243 243)"></div>

        <div class="tableBox_a test1" v-if="getUserRole('cf_top3')">
          <div class="titles">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            现金流入流出TOP3
          </div>

          <div class="row mt-3">
            <ABar style="width: 50%" :seriesIndex="1" ref="ABar2"></ABar>
            <div class="right">
              <div class="right_text">
                <div v-for="(item, index) in income_subjects" :key="index">
                  <div class="text_title" style="font-weight: 550">
                    {{ item.title }}
                  </div>

                  <div class="pl-3" v-if="index == 'income_subjects'">
                    <div
                      class="text_item"
                      v-for="(body, i) in item.body"
                      :key="i"
                    >
                      {{ i + 1 }}、{{ body.name }}流入金额为{{
                        thousandBitSeparator(body.value)
                      }}万元,占比为{{ body.rate }};
                    </div>
                  </div>
                  <div class="pl-3" v-else>
                    <div
                      class="text_item"
                      v-for="(body, i) in item.body"
                      :key="i"
                    >
                      {{ i + 1 }}、{{ body.name }}流出金额为{{
                        thousandBitSeparator(body.value)
                      }}万元,占比为{{ body.rate }};
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  capacity, //
  capacity_history,
  capacity_summa,
  in_and_out,
  top_n,
  history,
  analysis,
  structure,
} from "@/api/FinancialAnalysis.js";
import {
  FinWhitewashCal,
  causeWhitewash,
  logActivity,
  checkpoint,
} from "@/api.js";

export default {
  data() {
    return {
      tableList: {},
      shows: false,
      tableType: 2,
      income_subjects: {},
      tableData: [],
      dataList: {},
    };
  },
  components: {
    Stable: require("@/components/Table/in_out_table").default,
    ABar: require("@/components/CompanyTab/FinancialAnalysis/AnalysisOfSourceAndUsageDirection/ABar")
      .default,
    WaterfallBar:
      require("@/components/CompanyTab/FinancialAnalysis/AnalysisOfSourceAndUsageDirection/WaterfallBar")
        .default,
    CTable:
      require("@/components/CompanyTab/FinancialAnalysis/CashFlowCapacityAnalysis/Ctables.vue")
        .default,
  },
  created() {
    this.$VM[this.$route.name] = this;
  },
  methods: {
    search(param) {
      this.$loadingShow();
      let checkData = {
        company_code: param.company_code,
        menu_id: "6",
        parsing_time: param.year,
        industry_type: param.industry_name
          ? param.industry_name.split("/").reverse()[0]
          : "",
        industry_number: param.industry_code,
        industry_range: 0,
        report_time: param.report_time,
        report_type: param.report_type,
      };

      checkpoint(checkData).then(({ data }) => {
        if (data.Status != 1) {
          this.$loadingHide(); //隐藏加载动画
          return this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          this.$loadingHide();
          if (this.getUserRole("cf_overview")) {
            this.history(param); //历史趋势
            this.capacity(param); //行业对比
            this.in_and_out(param); //流入流出分析
          }
          if (this.getUserRole("cf_structure")) {
            structure(param).then((res) => {
              this.tableList = res.data;
              this.$loadingHide();
            });
          }
          if (this.getUserRole("cf_top3")) {
            this.top_n(param);
          }
        }
        logActivity(checkData);
      });
    }, // 流入流出
    in_and_out(param) {
      in_and_out(param).then(({ data }) => {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.ABar1.setData1(data.chart);
          });
          this.$loadingHide();
        }, 500);
      });
    },

    //行业对比
    capacity(param) {
      capacity(param).then(({ data }) => {
        let dataList = [];
        dataList.push(data.data[0], data.data[1], data.data[4], data.data[5]);
        setTimeout(() => {
          this.$nextTick(() => {
            this.tableData = dataList;
          });
          this.$loadingHide();
        }, 500);
      });
    },
    // 历史趋势
    history(param) {
      history(param).then(({ data }) => {
        this.dataList = data;
        this.$loadingHide();
      });
    },
    // 现金流指标得分趋势图
    capacity_history(param) {
      var param = param || this.param;
      const period = this.$VM.TrendChartOfCashFlowIndexScore.period;
      // console.log(param);
      capacity_history({
        ...param,
        period: period,
      }).then(({ data }) => {
        this.$VM.TrendChartOfCashFlowIndexScore.init(data.y_axis, data.x_axis);
        this.$loadingHide();
      });
    },
    // 流入流出TOP3
    top_n(param) {
      top_n(param).then(({ data }) => {
        this.income_subjects = data;
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.ABar2.setData2(data);
          });
          this.$loadingHide();
        }, 500);
      });
    },
    tabClick(type) {
      this.tableType = type;
    },
    toggleTypes(type) {
      this.shows = type;
      if (type == true) {
        setTimeout(() => {
          this.getLinCharts();
        }, 500);
      }
    },
    getLinCharts() {
      const dataTime = this.dataList.years;
      const data1 = this.dataList["经营活动现金流量流入流出比"];
      const data2 = this.dataList["现金流动负债比(%)"];
      const data3 = this.dataList["盈利现金倍数"];
      const data4 = this.dataList["资产现金回收率(%)"];
      var option = {
        color: ["#529EE2", "#F7882B", "#FAD33F", "#3EBB9F"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "经营活动现金流量流入流出比",
            "年化后现金流动负债比(%)",
            "盈利现金倍数",
            "年化后资产现金回收率(%)",
          ],
          icon: "circle",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dataTime,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "经营活动现金流量流入流出比",
            type: "line",
            data: data1,
          },
          {
            name: "年化后现金流动负债比(%)",
            type: "line",
            data: data2,
          },
          {
            name: "盈利现金倍数",
            type: "line",
            data: data3,
          },
          {
            name: "年化后资产现金回收率(%)",
            type: "line",
            data: data4,
          },
        ],
      };
      this.$echarts.init(this.$refs.linChartCunt).setOption(option);
    },

    thousandBitSeparator: function (cellValue) {
      let value = cellValue / 10000;
      // console.log(value);
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
#cash_flow {
  margin-top: 15px;

  #AnalysisOfSourceAndUsageDirection {
    .titles {
      font-size: 20px;
      font-weight: 550;
      color: #333;
      img {
        margin-right: 5px;
      }
    }
    .content_a {
      background: #fff;
      padding: 20px;

      .content1 {
        display: flex;
        justify-content: space-between;
        .content2 {
          width: 49%;
          padding-bottom: 10px;
          min-height: 350px;
          color: #666666;
          font-size: 18px;
          .linChartCunt {
            width: 100% !important;
            height: 350px !important;
          }
          > .unit {
            position: relative;
            z-index: 1;
            font-size: 14px;
            color: #999999;
            float: right;
            margin-top: 56px;
            margin-right: 20px;
          }

          .left {
            margin-top: 10%;
            padding: 20px;
            font-size: 16px;
            color: #333333;

            > div {
              margin-bottom: 10px;
            }
          }

          > .right {
            width: 100%;
            margin-top: 20px;

            > div {
              margin-bottom: 10px;

              .content_item {
                padding-left: 10px;
              }
            }
          }
          .btnActive {
            background: #3a85fd;
            color: #fff;
            border: 1px solid #3a85fd;
          }
        }
      }
    }

    .table_structure {
      margin-top: 15px;
      padding: 20px;
      background: #fff;
    }
    .tableBox_a {
      margin-top: 15px;
      padding: 20px;
      background: #fff;
      .right {
        width: 50%;
        margin-top: 30px;
        .right_text {
          width: 100%;
          padding: 15px;
          background: rgb(241, 245, 255);
        }
        .text_title {
          margin-bottom: 5px;
          margin-top: 5px;
          font-size: 16px;
          color: #333333;
        }
        .text_item {
          // padding-left: 10px;
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
}
</style>